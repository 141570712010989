<ng-template #matrix let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Price Matrix</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
	  <div class="mb-3">

		<!-- Save Button -->
		<div class="row mb-2">
		  <div class="col-12 text-right">
			<button mat-raised-button color="accent" (click)="saveMatrix()">SAVE</button>
		  </div>
		</div>

		<!-- Search Input -->
		<div class="row mb-2">
		  <div class="col-12">
			<mat-form-field appearance="outline" class="search-field">
			  <mat-label>Search Product Lines</mat-label>
			  <input matInput placeholder="Search..." [(ngModel)]="searchText" (input)="filterProductLines()">
			  <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchText" (click)="clearSearch()">
				<mat-icon>clear</mat-icon>
			  </button>
			</mat-form-field>
		  </div>
		</div>

		<!-- Customer Matrix Form (Compact 5-Column Layout) -->
		<div class="row">
		  <div class="col-md-2" *ngFor="let p of filteredProductLines">
			<div class="card product-card">
			  <!-- Product Line Name with Enhanced Typography -->
			  <h6 class="product-line-title">{{ p.line_field }}</h6>

			  <!-- Discount Field -->
			  <div class="form-group compact-form-group">
				<label for="discount-{{p.line_field}}">Discount</label>
				<input id="discount-{{p.line_field}}" type="text" class="form-control"
					   [value]="getCustomerMatrixDiscount(p.line_field)"
					   (input)="updateCustomerMatrix(p, $event.target.value)">
			  </div>

			  <!-- Level Field -->
			  <div class="form-group compact-form-group">
				<label for="level-{{p.line_field}}">Level</label>
				<input id="level-{{p.line_field}}" type="text" class="form-control"
					   [value]="getCustomerMatrixLevel(p.line_field)"
					   (input)="updateCustomerMatrixLevel(p, $event.target.value)">
			  </div>
			</div>
		  </div>
		</div>

	  </div>
	</div>




	<div class="modal-footer">
	</div>
</ng-template>
<ng-container *ngIf="settingsFrom">
	<form [formGroup]="settingsFrom" (submit)="updateCustomerSettings()">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button mat-raised-button [color]="settingsFrom.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
		</div>
		<div class="col-md-12 mt-2 mb-2" *ngIf="settingsFrom.get('salestype').value === 'M'">
			<button mat-flat-button color="accent" (click)="$event.preventDefault();loadMatrix()">Edit Matrix</button>
		</div>
		<div class="row" @grow>
			<div class="col-md-12">
				<mat-slide-toggle formControlName="disabletrans">Disabled</mat-slide-toggle>
			</div>

			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Username</mat-label>
					<input matInput required value="" formControlName="username">
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Password</mat-label>
					<input matInput required value="" formControlName="password">
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Customer Type</mat-label>
					<mat-select formControlName="typeid">
						<mat-option *ngFor="let tpe of customertypes" [value]="tpe?.typeid">
							{{tpe.typename}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="" @grow>
					<mat-label>Sales Person</mat-label>
					<mat-select formControlName="salesman" [(value)]="customer.customer.salesman">
						<mat-option *ngFor="let person of salesman" [value]="person.salesmancode">
							{{ person.salesmancode}} {{person.salesmanname }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Price List</mat-label>
					<mat-select formControlName="salestype">
						<mat-option *ngFor="let tpe of salestypes" [value]="tpe?.typeabbrev">
							{{ tpe.typeabbrev }} - {{tpe.sales_type}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12" *ngIf="settingsFrom.get('salestype').value == 'O'">
				<mat-form-field appearance="outline" class="">
					<mat-label>Base Price</mat-label>
					<mat-select formControlName="salestype2">
						<mat-option *ngFor="let tpe of salestypes" [value]="tpe?.typeabbrev">
							{{ tpe.typeabbrev }} - {{tpe.sales_type}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Discount Level</mat-label>
					<mat-select formControlName="pricelevelprice">
						<mat-option value=""> --- </mat-option>
						<mat-option *ngFor="let tpe of price_levels" [value]="tpe?.price_level_id">
							{{ tpe.price_level_id }} - {{tpe.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Discount</mat-label>
					<input matInput value="0" type="text" class="text-right" formControlName="discount">
					<mat-icon matSuffix><small>&nbsp;&nbsp;%</small></mat-icon>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Commission Rate</mat-label>
					<input matInput value="0" type="text" class="text-right" formControlName="commpct">
					<mat-icon matSuffix><small>&nbsp;&nbsp;%</small></mat-icon>
				</mat-form-field>
			</div>

			<div *ngIf="clubs.length > 0" class="col-md-12">
				<h5>Clubs Joined</h5>
				<hr>
				<ul>
					<li *ngFor="let club of customer.clubs_joined">
						<div class="spec-label bg-white">
							{{ club.club }} : {{ club.accountid }} <button color="warning" mat-icon-button (click)="$event.preventDefault();removeClub(club)"><mat-icon>remove</mat-icon></button>
						</div>

					</li>
				</ul>
				<div class="row">
					<div class="col-md-6">
						<mat-form-field appearance="outline" class="">
							<mat-label>Club to Join</mat-label>
							<mat-select formControlName="clubid">
								<mat-option *ngFor="let tpe of clubs" [value]="tpe?.id">
									{{ tpe.club }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-5">
						<mat-form-field appearance="outline" class="">
							<mat-label>Account ID#</mat-label>
							<input matInput value="0" type="text" class="text-right" formControlName="clubacctid">
						</mat-form-field>
					</div>
					<div class="col-md-1">
						<button color="primary" mat-icon-button (click)="$event.preventDefault();addClub()"><mat-icon>save</mat-icon></button>
					</div>
				</div>
			</div>
		</div>
	</form>
</ng-container>