<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card mt-0 tp-box ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title" (click)="back()"><i class="material-icons">arrow_left</i>
							Taxes
						</h4>
						<div class="card-category text-right">
							Tax Setup & Maintenance
						</div>
					</div>
					<div class="card-body m-0 p-0">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Tax Authorities">
								<app-tax-authorities>
								</app-tax-authorities>
							</mat-tab>
							<mat-tab label="Tax Rates">
								<app-tax-rates></app-tax-rates>
							</mat-tab>
							<mat-tab label="Tax Groups">
								<app-tax-groups></app-tax-groups>
							</mat-tab>
							<mat-tab label="Tax Categories">
								<app-tax-categories></app-tax-categories>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
