import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';

import { formatDate } from '@angular/common';
import { interval, Observable } from 'rxjs';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-dreg',
	templateUrl: './dreg.component.html',
	styleUrls: ['./dreg.component.scss']
})
export class DregComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('cashModal') cashRef: ElementRef;
	dreg: any = {
		dregdate: new Date(),
		startcash: 0.00,
		endcash: 0.00,
		expenses: [],
		payments: [],
		cashdrawers: [{ 'p': 0, 'n': 0, 'd': 0, 'q': 0, 'one': 0, 'five': 0, 'ten': 0, 'twen': 0, 'fif': 0, 'hun': 0, 'total': 0 }, { 'p': 0, 'n': 0, 'd': 0, 'q': 0, 'one': 0, 'five': 0, 'ten': 0, 'twen': 0, 'fif': 0, 'hun': 0, 'total': 0 }],
		salestotal: 0.00,
		taxtotal: 0.00,
		calctotal: 0.00,
		calcdeposit: 0.00,
		deposit: 0.00,
		overshort: 0.00,
		loccode: '',
	};

	cashBills = [
		{ label: '$100.00', placeholder: 'Hundred', model: 'hun' },
		{ label: '$50.00', placeholder: 'Fifty', model: 'fif' },
		{ label: '$20.00', placeholder: 'Twenty', model: 'twen' },
		{ label: '$10.00', placeholder: 'Ten', model: 'ten' },
		{ label: '$5.00', placeholder: 'Five', model: 'five' },
		{ label: '$1.00', placeholder: 'One', model: 'one' },
		{ label: '$0.25', placeholder: 'Quarters', model: 'q' },
		{ label: '$0.10', placeholder: 'Dimes', model: 'd' },
		{ label: '$0.05', placeholder: 'Nickels', model: 'n' },
		{ label: '$0.01', placeholder: 'Pennies', model: 'p' }
	]

	location: any = new UntypedFormControl('');
	user: any = [];
	color: any = '';
	branches: any = [];
	filename: any = 'DREG';
	canedit = true;
	printing: any = false;
	dirty: any = false;
	sub: any = false;
	sending: any = false;
	sent: any = false;
	constructor(private globalsService: GlobalsService, private reportsService: ReportsService, private modalService: NgbModal, private printService: PrintService, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			this.location.value = this.user.user.defaultlocation.loccode;
		});
		this.globalsService.getUserLocations().subscribe((results: any) => {
			this.branches = results;
		});

		const ddate = formatDate(this.dreg.dregdate, 'yyyy-MM-dd', 'en_US');
		this.sent = false;




		this.getPayments();

		this.sub = interval(10000).subscribe((val) => { this.autosave(); });
	}
	autosave() {

		if (this.dirty) {
			this.postdreg();
			//console.log('Autosaving...');
			this.dirty = false;
		}
	}
	changeLocation(e) {
		this.getPayments();
	}
	getPayments() {
		const ddate = formatDate(this.dreg.dregdate, 'yyyy-MM-dd', 'en_US');
		const data = { 'dregdate': ddate, 'dreglocation': this.location.value };
		this.globalsService.getDregPayments(data).subscribe((results: any) => {
			
			this.sent = results.closed;
			this.dreg.payments = results['payments'];
			this.dreg.salestotal = parseFloat(results['salestotal']).toFixed(2);
			this.dreg.taxtotal = parseFloat(results['taxtotal']).toFixed(2);
			this.dreg.deposit = parseFloat(results['deposit']).toFixed(2);
			this.dreg.startcash = parseFloat(results['startcash']).toFixed(2);
			this.dreg.endcash = parseFloat(results['pmcash']).toFixed(2);
			this.dreg.expenses = results['expenses'];
			this.dreg.cashdrawers = results['cashdrawers'];
			this.recalc();
		});

	}
	
	dregdatechange(e) {

		const ddate = formatDate(e, 'yyyy-MM-dd', 'en_US');
		const data = { 'dregdate': ddate, 'dreglocation': this.location.value };
		this.globalsService.getDregPayments(data).subscribe((results: any) => {
			
			this.sent = results.closed;
			this.dreg.payments = results['payments'];
			this.dreg.salestotal = parseFloat(results['salestotal']).toFixed(2);
			this.dreg.taxtotal = parseFloat(results['taxtotal']).toFixed(2);
			this.dreg.deposit = parseFloat(results['deposit']).toFixed(2);
			this.dreg.startcash = parseFloat(results['startcash']).toFixed(2);
			this.dreg.endcash = parseFloat(results['pmcash']).toFixed(2);
			this.dreg.expenses = results['expenses'];
			this.dreg.cashdrawers = results['cashdrawers'];

			this.recalc();
			//if (ddate != formatDate(new Date(), 'yyyy-MM-dd', 'en_US')) {
			//	this.canedit = false;
			//} else {
			//	this.canedit = true;
			//}
		});
	}

	recalc() {
		this.dreg.calctotal = parseFloat(this.dreg.salestotal);
		for (let x = 0; x < this.dreg.payments.length; x++) {

			if (this.dreg.payments[x].cash == 0) {
				this.dreg.calctotal = this.dreg.calctotal - this.dreg.payments[x].total;
			}
		}
		this.dreg.calctotal = this.dreg.calctotal - parseFloat(this.dreg.endcash);
		this.dreg.calctotal = this.dreg.calctotal + parseFloat(this.dreg.startcash);

		this.dreg.calctotal = parseFloat(this.dreg.calctotal);
		//Now include expenses for final deposit and calculate over/short

		this.dreg.calcdeposit = this.dreg.calctotal;
		for (let x = 0; x < this.dreg.expenses.length; x++) {
			this.dreg.calcdeposit = this.dreg.calcdeposit - this.dreg.expenses[x].total;
		}

		this.dreg.overshort = Math.round(((this.dreg.deposit - this.dreg.calcdeposit) * 100)) / 100;

		this.dreg.calcdeposit = parseFloat(this.dreg.calcdeposit).toFixed(2);
		//this.dreg.deposit = parseFloat(this.dreg.deposit).toFixed(2);
		this.dreg.calctotal = parseFloat(this.dreg.calctotal).toFixed(2);
		this.dirty = true;
	}
	recalc2() {
		this.dreg.calctotal = parseFloat(this.dreg.salestotal).toFixed(2);
		for (let x = 0; x < this.dreg.payments.length; x++) {

			if (this.dreg.payments[x].cash == 0) {
				this.dreg.calctotal = this.dreg.calctotal - parseFloat(this.dreg.payments[x].total);
			}
		}
		this.dreg.calctotal = this.dreg.calctotal - parseFloat(this.dreg.endcash);
		this.dreg.calctotal = this.dreg.calctotal + parseFloat(this.dreg.startcash);

		//Now include expenses for final deposit and calculate over/short
		this.dreg.calcdeposit = parseFloat(this.dreg.calctotal);

		for (let x = 0; x < this.dreg.expenses.length; x++) {
			this.dreg.calcdeposit = Math.round(((this.dreg.calcdeposit - this.dreg.expenses[x].total) * 100)) / 100;
		}

		this.dreg.deposit = parseFloat(this.dreg.calcdeposit) + parseFloat(this.dreg.overshort);

		//this.dreg.calcdeposit = parseFloat(this.dreg.calcdeposit).toFixed(2);
		this.dreg.deposit = parseFloat(this.dreg.deposit).toFixed(2);
		this.dreg.calctotal = parseFloat(this.dreg.calctotal).toFixed(2);
		this.dirty = true;
	}
	addexp(e) {
		this.dreg.expenses.push({ name: '', total: 0 });
	}
	cashedit() {

		this.modalService.open(this.cashRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			//cashdrawers

		}, (reason) => {});

	}
	updateCash() {
		this.dreg.endcash = 0;
		for (let x = 0; x < this.dreg.cashdrawers.length; x++) {
			const drawer = this.dreg.cashdrawers[x];
			drawer.total = this.cashBills.reduce((sum, bill) => sum + (parseFloat(drawer[bill.model]) || 0), 0);
			this.dreg.endcash += drawer.total;
			drawer.total = parseFloat(drawer.total).toFixed(2);
		}
		this.dreg.endcash = parseFloat(this.dreg.endcash).toFixed(2);
		this.recalc();
	}


	removeexpense(expnum) {
		this.dreg.expenses.splice(expnum, 1);
		this.recalc();
	}

	postdreg() {

		this.dreg.loccode = this.location.value;
		this.dreg.email = false;
		this.globalsService.postDreg(this.dreg).subscribe((results: any) => {});
	}

	emailDREG() {

		this.dreg.loccode = this.location.value;
		this.dreg.email = true;
		this.sending = true;
		this.globalsService.closeDreg(this.dreg).subscribe((results: any) => {
			this.exportPdf();
			this.sent = true;
		});
		
	}

	back() {
		this.location.back();
	}



	exportPdf() {
		this.printing = true;
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);

		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.printing = false;
			this.sending = false;
			this.globalSearchService.showNotification('DREG successfully emailed', 'success', 'bottom', 'left');
		});

	}
}