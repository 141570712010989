import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Renderer2, TemplateRef, HostListener, Directive , SimpleChanges, ViewChildren, QueryList} from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { EducationService } from '../../../services/education.service';
import { OmsService } from '../../../services/oms.service';

export enum KEY_CODE {
	PLUS = 107,
		MINUS = 109,
		TAB = 9,
		PLUSTWO = 187,
}


@Component({
	selector: 'app-pos-item-search',
	templateUrl: './pos-item-search.component.html',
	styleUrls: ['./pos-item-search.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})

export class PosItemSearchComponent implements OnInit {

	@Input() customer: any = false;
	@Input() type: any = '10';
	@Output() addItem = new EventEmitter < any > ();
	@Output() updateCart = new EventEmitter < any > ();
	@Output() hasresults = new EventEmitter < any > ();
	@Output() addEduEvent = new EventEmitter < any > ();
	@ViewChild('searchkeywords') searchkeywordsRef: ElementRef;
	@Output() addMix = new EventEmitter < any > ();
	@Output() closed = new EventEmitter < any > ();
	@ViewChild('firstItem') firstItem: ElementRef;
	@ViewChildren('inputElement') inputElements: QueryList < ElementRef > ;

	config: any = [];
	itemSearchForm: UntypedFormGroup;
	regForm: UntypedFormGroup;
	search_customer_items = new UntypedFormControl(false)
	search_vendor_items = new UntypedFormControl(false)
	filters: any = [];
	screenHeight: number;
	screenWidth: number;

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '200', value: '200' },
		{ name: '300', value: '300' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	sorts = [
		{ name: 'All Availability, Sell Low To High', value: 'avail.sell|ASC' },
		{ name: 'All Availability, Sell High To Low', value: 'avail.sell|DESC' },
		{ name: 'Local Availability, Sell Low To High', value: 'local.sell|ASC' },
		{ name: 'Local Availability, Sell High To Low', value: 'local.sell|DESC' },
		{ name: 'Sell Low To High', value: 'price|ASC' },
		{ name: 'Sell High To Low', value: 'price|DESC' },
		{ name: 'Name ASC', value: 'description|ASC' },
		{ name: 'Name DESC', value: 'description|DESC' },
		{ name: 'SKU ASC', value: 'stockid|ASC' },
		{ name: 'SKU DESC', value: 'stockid|DESC' },
	];

	showGuide = false;
	showfilters = false;
	searching = false;
	addingitems = false;
	inventorysearching: any = false;
	filtered_items: any = [];
	purchase_items: any = [];
	item_details: any = [];
	previous_search = '';
	salesman: any = false;
	//modal
	selecteditem: any = false
	itemhistory: any = false;
	editing_order: any = false;
	user: any = [];
	expanded_rows: any = [];
	promo: any = false;
	event: any = [];

	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('itemHistoryRef') itemHistoryRef: ElementRef;
	@ViewChild('configproductele') configproductele: ElementRef;
	@ViewChild('nonstock') nonstockref: ElementRef;
	@ViewChild('itemdetails') itemDetails: ElementRef;
	@ViewChild('itemPosResults') itemPosResultsRef = {} as TemplateRef < any > ;
	@ViewChild('mmmodal') mmodalref: ElementRef;
	@ViewChild('edumodal') eventmodalref: ElementRef;

	constructor(private bottomSheet: MatBottomSheet, private educationService: EducationService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private renderer: Renderer2) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.user = this.globalSearchService.user;
		this.getScreenSize();
	}

	// @HostListener('window:resize', ['$event'])

	ngOnInit(): void {

		if (this.type == '11') {
			this.search_customer_items.setValue(false);
		}

		if (this.type == '21') {
			this.search_vendor_items.setValue(false);
		}

		if (this.user.value.user.salesman != '') {
			this.salesman = this.user.value.user.salesman;
		}

		switch (this.config.env.package) {
		case 'beauty':
			this.itemSearchForm = this.fb.group({
				keywords: ['', Validators.required],
				limit: [this.limits[3].value, Validators.required],
				sort: [this.sorts[8].value, Validators.required],
			});
			break;
		default:
			this.itemSearchForm = this.fb.group({
				keywords: ['', Validators.required],
				limit: [this.limits[2].value, Validators.required],
				sort: [this.sorts[2].value, Validators.required],
			});
			break;
		}

		//this.itemSearchForm.get('keywords').setValue('5nn');
		setTimeout(() => {
			if (this.itemSearchForm.get('keywords')) {
				document.getElementById('searchkeywords').focus();
			}
		}, 500);


	}

	selectText(event: FocusEvent) {
		const input = event.target as HTMLInputElement;
		if (input) {
			setTimeout(() => {
				input.select();
			}, 0);
		}
	}

	private focusFirstItem() {
		setTimeout(() => {
			if (this.firstItem) {
				const firstInput = this.firstItem.nativeElement;
				firstInput.focus();
			}
		}, 300);
	}


	getScreenSize(event ? ) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	showPurchases(stockidid: any) {

	}
	runFunction(newvalue: any) {
		this.itemSearchForm.get('keywords').setValue(newvalue);
		this.itemSearch();
	}

	addNonStockItem(event: any) {
		//not in use moved to parent
		const item = event;
		const data = { item: item, debtorno: this.customer.debtorno, branchcode: this.customer.branchcode, location: this.customer.defaultlocation }
		//
		// 		this.orderService.addNonStock(data).subscribe((results: any) => {
		// 			this.updateCart.emit(results);
		// 			this.globalSearchService.showNotification(item.description + ' x ' + item.qty + ' Added', 'success', 'bottom', 'right');
		// 		});
	}


	itemSearch() {
		const keywords = this.itemSearchForm.get('keywords').value;
		if (keywords != '') {

			this.globalSearchService.hideSideBar();

			this.searching = true;
			this.addingitems = true;

			this.filtered_items = [];

			if (this.inventorysearching) {
				this.inventorysearching.unsubscribe()
			}

			const data = {
				keywords: keywords,
				limit: this.itemSearchForm.get('limit').value,
				sort: this.itemSearchForm.get('sort').value,
				customer: this.customer,
				filters: this.filters,
				customer_items_only: this.search_customer_items.value,
				vendor_items_only: this.search_vendor_items.value,
				type: this.type,
				user: this.user._value.user
			};

			this.hasresults.emit(false);

			this.inventorysearching = this.orderService.getItemSearch(data).subscribe((items: any) => {
				this.filtered_items = items
				this.searching = false;
				if (items.length) {

					if (items.length === 1) {
						if (!items[0].ispromo && !items[0].isevent) {
							this.closed.emit(true);
							this.addToOrder(items[0]);
							this.resetSearch();
						}  else if (!items[0].isevent){
							this.triggerEdu(items[0]);
						} else {
							this.triggerMm(items[0])
						}
					}

					if (items.length > 1) {
						const config = {
							hasBackdrop: true,
							margin: 0,
							padding: 0,
							//backdropClass: 'p-0 m-0'
							//panelClass: 'custom-width',
						}

						const bottomSheet = this.bottomSheet.open(this.itemPosResultsRef, config);
						this.focusFirstItem();
						bottomSheet.afterDismissed().subscribe((result) => {
							this.closed.emit(true);
						});
					}

					if (!this.showfilters) {
						// this.itemSearchForm.get('keywords').setValue('');
					}
				} else {
					this.itemSearchForm.get('keywords').setValue('')
					setTimeout(() => this.searchkeywordsRef.nativeElement.focus(), 0);

					this.globalSearchService.showNotification('No Results for ' + this.itemSearchForm.get('keywords').value, 'danger', 'bottom', 'right')
				}
			});
		}
	}

	setParent(event: any, parent: any) {
		parent.qty = event.target.value;
		//multiply base values by current
		parent.intros.forEach((intro: any) => {
			intro.floor = intro.original_floor
			intro.floor = intro.floor * parent.qty;
			this.updateMMQty(intro);
		})

	}


	getNumberOfNonType1Intros(intros): number {
		let count = 0;
		for (const intro of intros) {
			if (intro.type !== '1' && intro.type !== 1) {
				count++;
			}
		}
		return count > 0 ? count : 1;
	}

	closeBottom() {
		this.bottomSheet.dismiss(this.itemPosResultsRef);
	}

	expand_row(index) {
		this.expanded_rows.includes(index) ? this.expanded_rows.splice(this.expanded_rows.indexOf(index), 1) : this.expanded_rows.push(index);

	}

	isExpanded(index) {
		return this.expanded_rows.includes(index);
	}


	getLineTotal(item: any): number {
		let qty = item.quantity
		if (!qty || item.quantity == 0) {
			qty = 1;
		}

		if (this.config.env.package == 'beauty') {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.customer.discount * item.price))
		} else {
			var value = this.financial(qty) * (this.financial(item.price) - this.financial(this.customer.discount * item.price)) + this.financial(item.fetvalue)
		}

		return value;

	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	updateFilters(event: any) {
		this.filters = event;
		this.itemSearch();
	}

	updateFilteredItems(event: any) {
		this.searching = true;
	}


	updateItem(data: any) {
		const itemdata = this.filtered_items.filter((item: any) => {
			return data.item.stockid == item.stockid
		})[0]

		const index = this.filtered_items.indexOf(itemdata);
		this.filtered_items[index] = itemdata;
	}

	decrement(item: any) {
		//allwoing negatives
		const index = this.filtered_items.indexOf(item);
		//if (this.filtered_items[index].quantity > 0) {
		this.filtered_items[index].quantity -= 1;
		//}
	}

	increment(item: any) {
		const index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity += 1;
	}

	updateSearchPrice(event: any, item: any) {
		const index = this.filtered_items.indexOf(item);

		this.filtered_items[index].price = this.financial(event.target.value);
	}

	updateSearchQuantity(event: any, item: any) {
		const index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity = event.target.value;
	}

	addToOrder(item: any) {

		if (item.quantity == 0) {
			item.quantity = 1;
		}

		const copyOfItems = Object.assign([], this.filtered_items);
		const this_index = '';

		const add_items = [];
		const allow = true;
		copyOfItems.forEach((items: any, index) => {
			items.allownegative = true;
			items.isnegative = false;

			if (this.type == '21') {

				const qty_request = parseFloat(items.quantity);
				if (qty_request != 0) {
					const qty_min = parseFloat(items.minorderqty);
					const qty_max = parseFloat(items.maxorderqty);

					const qty_max_stock = parseFloat(item.minmax.maximum);
					const qty_min_stock = parseFloat(item.minmax.minimum);

					if (qty_min > 0 && qty_max > 0) {
						if (qty_request > qty_max) {
							// allow = confirm('Attempting to Purchase more than '+qty_max+' for '+items.description+', procced?');
						}

						if (qty_request < qty_min) {
							// allow = confirm('Attempting to Purchase less than '+qty_min+' for '+items.description+', procced?');
						}
					}

					if (allow) {
						if (qty_max_stock > 0 && qty_max_stock > 0) {
							if (qty_request > qty_max_stock) {
								// allow = confirm('Attempting to Purchase more than '+qty_max_stock+' for '+items.description+', procced?');
							}

							if (qty_request < qty_min_stock) {
								// allow = confirm('Attempting to Purchase less than '+qty_min_stock+' for '+items.description+', procced?');
							}
						}
					}
				}
			}
			if (allow) {
				if (parseFloat(items.quantity) != 0) {
					add_items.push(items);
				}
			}
		});


		this.addItem.emit(add_items);
		this.hasresults.emit(false);
		this.filtered_items.forEach(i => { i.quantity = 0; });
		this.resetSearch();
	}

	addMultipleToOrder() {
		this.filtered_items.forEach(element => {
			element.quantity > 0 ? this.addToOrder(element) : '';
		});
		this.resetSearch();
	}

	resetSearch() {
		this.itemSearchForm.get('keywords').setValue('');

		setTimeout(() => this.searchkeywordsRef.nativeElement.focus(), 0);
		//this.filtered_items = [];
		this.hasresults.emit(false);
		//this.bottomSheet.dismiss(this.itemPosResultsRef);
	}


	setPurchOrderOptions(cart_id: any, selected: any) {
		const data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.purchase_items = [];
		});

		this.updateCart.emit(true);
	}


	//modals
	viewitemOrderHistory(item: any) {
		this.selecteditem = item;
		this.customerService.getCustomerHistoryItem(this.customer.debtorno, item.stockid).subscribe((result: any) => {
			this.itemhistory = result
			this.inventoryService.getPurchaseHistory(item.stockid).subscribe(async (result: any) => {
				this.itemhistory.purchase = result;
			})

			this.modalService.open(this.itemHistoryRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.itemhistory = [];
				this.selecteditem = false;
			});
		});
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		const data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	// searchAttributes(value: string) {
	// 	this.filteredattributes = this.globalSearchService.filterItem(this.allattributes, value, 'label,value,key');
	// }

	toggleFilters() {
		this.showfilters = (this.showfilters) ? false : true;

		if (!this.showfilters) {
			this.filters = [];
			this.itemSearch();
		}

	}

	triggerMm(item: any) {
		const data = {
			stockid: item.stockid,
			qty: item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.bottomSheet.dismiss(this.itemPosResultsRef);

			this.modalService.open(this.mmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.promo = false;
				this.selecteditem = false;
			});
		})
	}

	updateMMQty(mm: any) {
		mm.error = true;
		const items = mm.items || [];

		const used = items.reduce((accumulator, item) => accumulator + parseFloat(item.quantity), 0);

		const floorValue = this.financial(mm.floor);
		let remainingValue = floorValue - this.financial(used);

		if (remainingValue < 0) {
			mm.error = true;
			let overage = -remainingValue; // Make the overage positive

			for (let i = items.length - 1; i >= 0; i--) {
				const item = items[i];
				const itemQuantity = parseFloat(item.quantity);

				if (itemQuantity > 0) {
					if (itemQuantity >= overage) {
						item.quantity = Math.max(0, Math.floor((itemQuantity - overage) * 100) / 100); // Ensure two decimal places
						overage = 0;
						break;
					} else {
						item.quantity = 0;
						overage -= itemQuantity;
					}
				}
			}

			this.globalSearchService.showNotification('Error, Total Qty Adjusted: Items Quantities Reduced', 'danger', 'bottom', 'right');
		} else {
			mm.error = remainingValue !== 0;
		}

		return Math.max(0, remainingValue);
	}


	decrementmm(item: any, intro: any) {
		if (item.quantity > 0) {
			item.quantity -= 1;
			this.updateMMQty(intro);
		}
	}

	incrementmm(item: any, intro: any) {
		if (this.updateMMQty(intro) > 0) {
			item.quantity += 1;
			this.updateMMQty(intro);
		} else {
			this.globalSearchService.showNotification('Promo Requirements Filled', 'danger', 'bottom', 'right')
		}
	}

	qtyMM(event: any, item: any, intro: any) {
		//orig -> used
		item.quantity = 0;
		const original_qty = item.quantity;
		let qty_used = this.financial(event.target.value);
		const test_qty = parseFloat(original_qty) + parseFloat(event.target.value);
		const maxqty = this.updateMMQty(intro);



		if (test_qty > maxqty) {
			qty_used = maxqty;
			this.globalSearchService.showNotification('Maximum of ' + qty_used + ' Allowed', 'danger', 'bottom', 'right')
		}

		if(isNaN(qty_used)){
			event.target.value = 0;
			qty_used = 0;
		}else{
			event.target.value = qty_used;
		}

		item.quantity = qty_used
		this.updateMMQty(intro);

	}

	addMM(intro: any) {
		//test kit first
		this.addMix.emit(intro);
		this.modalService.dismissAll();
		this.filtered_items = [];
		this.updateCart.emit(true);

		this.resetSearch();

	}

	addPromo(item: any) {
		const data = {
			stockid: item.stockid,
			qty: item.quantity,
		}

		this.inventoryService.getPromo(data).subscribe(r => {
			this.promo = r;
			this.promo.itemdesc = item;
			this.promo.qty = r.qty
			this.addMM(this.promo);
		})
	}

	triggerEdu(item: any) {
		const data = {
			stockid: item.stockid,
		}
		this.bottomSheet.dismiss(this.itemPosResultsRef);
		this.educationService.getEventByStockId(data).subscribe(r => {
			this.event = r;
			this.regForm = this.fb.group({
				eduname: ['', Validators.required],
				educell: ['', Validators.required],
				eduemail: ['', Validators.required],
				eduinsta: [''],
			});
			this.modalService.open(this.eventmodalref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.event = false;
			});
		})
	}
	addSeat() {


		const s = { name: this.regForm.get('eduname').value, cell: this.regForm.get('educell').value, email: this.regForm.get('eduemail').value, insta: this.regForm.get('eduinsta').value };
		if (this.regForm.valid) {
			this.event.seats.push(s);
			this.regForm = this.fb.group({
				eduname: ['', Validators.required],
				educell: ['', Validators.required],
				eduemail: ['', Validators.required],
				eduinsta: [''],
			});
		}
		const seatcount = this.event.seats.length;
		this.event.total = this.financial(seatcount * parseFloat(this.event.discount));

	}

	remSeat(seat: any) {
		const index = this.event.seats.indexOf(seat);
		if (index >= 0) {
			this.event.seats.splice(index, 1);
		}
		const seatcount = this.event.seats.length;
		this.event.total = this.financial(seatcount * parseFloat(this.event.discount));
	}
	addedureg() {
		//test kit first
		this.orderService.addEdu(this.event, this.customer.debtorno, this.customer.branchcode).subscribe((results: any) => {
			if (results.success) {
				this.updateCart.emit(true);
				this.globalSearchService.showNotification(results.message, 'success', 'bottom', 'right')
				this.modalService.dismissAll();
			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});
	}

	toggleGuide() {
		this.showGuide = (this.showGuide) ? false : true;
	}

	emitClosed(value: any) {
		this.closed.emit(value)
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filtered_items && changes.filtered_items.currentValue.length) {
			this.focusFirstItem();
		}
	};

	focusQty() {

		const ref = document.getElementById('itemQty');
		setTimeout(() => {
			ref.focus();
		}, 0);
	}

	handleTabKey(event: KeyboardEvent, index: number) {
		const inputsArray = this.inputElements.toArray();
		if (event.key === 'Tab' && !event.shiftKey) {
			if (index === inputsArray.length - 1) {
				event.preventDefault();
				this.firstItem.nativeElement.focus();
			}
		}
	}

	handleEnterKey(event: KeyboardEvent, index: number) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}



	filterItems(intro) {
		if (intro.searchQuery) {
			intro.filteredItems = this.globalSearchService.filterItem(intro.items, intro.searchQuery, 'stockid,description,barcode,lineid')
		} else {
			intro.filteredItems = [...intro.items];
		}
	}
}
