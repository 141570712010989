import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { UsersService } from './users.service';
import { UserGroup, User } from '../classes/user';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { GlobalSearchService } from './globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
	providedIn: 'root'
})
export class OmsService {


	key = 'UET6DFMPAXW7BKCB';
	config: any;
	//todo make connection reusabel
	socket: any;
	details: any;
	user : any = [];
	environment = {
		production: false,
		SOCKET_ENDPOINT: 'wss://cleve.tireserv.com:5009',
		user: '',
	};
	package: any = '';

	constructor(public userService: UsersService, private http: HttpClient, private globalSearchService: GlobalSearchService,private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe( (result) => {
			this.user = result;
		});

		this.environment.SOCKET_ENDPOINT = this.config.apiServer.socket,
		this.environment.user = this.user.user.userid;

		this.globalSearchService.socket.subscribe( existingsocket => {
			if(existingsocket) {
				this.socket = existingsocket;
			}
		});
	}

	setupSocketConnection(token: string) {

		if(!this.socket) {

			if(!this.user) {
				token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			} else {
				token = this.user.user.userid;
			}

			this.socket = io(this.environment.SOCKET_ENDPOINT, {
				auth: { token },
				reconnection: false
			});

			this.globalSearchService.socket.next(this.socket);
		}

		this.globalSearchService.socket.subscribe((results: any[]) => {
			this.socket = results;
			this.socket.connected ? '' : this.socket.connect();
		});
	}

	// Handle message receive event
	subscribeToMessages = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if(!this.socket.connected) {
			this.joinRoom('messages');
		}

		this.socket.on('messagesent', msg => {
			return cb(null, msg);
		});
	}

	subscribeToRecScanUpdate = (cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.joinRoom('RecScanUpdate');
		this.socket.on('recscansent', msg => {
			return cb(null, msg);
		});

		this.socket.on('recscanupdated', msg => {
			return cb(null, msg);
		});
	}

	sendRecScanUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('recscansent', { data , roomName }, cb);}
	}

	subscribeToDriverChange = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.joinRoom('driverchange');

		this.socket.on('welcome', msg => {
			return cb(null, msg);
		});

		this.socket.on('driverchanged', msg => {
			return cb(null, msg);
		});
	}

	sendDriverUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('driverchanged', { data , roomName }, cb);}
	}

	subscribeToCarts = (cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.joinRoom('CartRoom');

		this.socket.on('cartupdate', msg => {
			return cb(null, msg);
		});

		this.socket.on('updatecarts', msg => {
			return cb(null, msg);
		});
	}

	sendCartUpdate = ({ data, roomName }, cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('cartupdate', { data , roomName }, cb);}
	}

	subscribeToCards = (cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.joinRoom('OrderBoard');

		this.socket.on('dispatchupdate', msg => {
			return cb(null, msg);
		});

		this.socket.on('cardupdate', msg => {
			return cb(null, msg);
		});

		this.socket.on('orderupdate', msg => {
			return cb(null, msg);
		});

		this.socket.on('pickupdate', msg => {
			 return cb(null, msg);
		});

		this.socket.on('orderitempick', msg => {
			 return cb(null, msg);
		});

		this.socket.on('loadbayupdate', msg => {
			console.log(msg);
			return cb(null, msg);
		})
	}

	subscribeToPuts = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		this.socket.on('pickupdate', msg => {
			return cb(null, msg);
		});
	}

	subscribeToPicks = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		this.socket.on('pickupdate', msg => {
			return cb(null, msg);
		});
	}

	subscribeToOrderPicks = (cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.socket.on('orderitempick', msg => {
			return cb(null, msg);
		});
	}

	subscribeToOrderUpdates = (cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		this.socket.on('orderupdate', msg => {
			return cb(null, msg);
		});
	}

	sendOrderCheckUpdate = ({ data, roomName }, cb) => {


		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('orderitemcheck', { data , roomName }, cb);}

	}

	sendOrderPickUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('orderitempick', { data , roomName }, cb);}
	}

	subscribeToOpenOrders = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		this.socket.on('openorders', msg => {
			return cb(null, msg);
		});
	}

	printCommandSent = false;

	setNewOrder() {
		this.printCommandSent = false;
	}

	setPrintedOrder() {
		this.printCommandSent = true;
	}

	getPrintStatus(){
		return this.printCommandSent;
	}

// 	sendPrintCommand = ({ data, roomName }, cb) => {
// 		if (!this.socket) {
// 			this.setupSocketConnection(this.user.user.userid);
// 		}
//
// 		if (this.socket && !this.printCommandSent) {
//
// 			this.socket.emit('printPdf', { data, roomName }, () => {
//
// 				if (this.socket) {
// 					this.socket.disconnect();
// 					this.socket = null;
// 				}
// 				if (cb) {
// 					cb();
// 				}
// 				// Set printCommandSent to false after everything is done
// 				this.printCommandSent = false;
// 			});
// 			this.printCommandSent = true; // Mark command as sent
// 		} else {
// 			console.log("Print command already sent or socket not available.");
// 		}
// 	}

	sendPrintCommand = ({ data, roomName }, cb) => {

		if (!this.socket) {
			this.setupSocketConnection(this.user.user.userid);
		}

		if (this.socket && !this.printCommandSent) {
			// alert('sending');
			//"Sending print command...");
			console.log('sent');
			this.socket.emit('printPdf', { data, roomName }, () => {

				if (this.socket) {
					this.socket.disconnect();
					this.socket = null;
					this.printCommandSent = true;
					console.log('Socket disconnected.');
				}
				if (cb) {
					cb();

					if(this.socket) {
						this.socket.disconnect();
						this.socket = null;
						this.printCommandSent = true;
						console.log('Socket disconnected Again.');
					}
				}
			});
		} else {
			//"Print command already sent or socket not available.");
		}
	}

	sendOrderUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('orderupdate', { data , roomName }, cb);}

	}

	sendOpenOrderUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('openorders', { data , roomName }, cb);}

	}

	sendSnoozeUpdate = ({ data, roomName }, cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('snoozekupdate', { data , roomName }, cb);}

	}

	sendSnoozeRemove = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('snoozeremove', { data , roomName }, cb);}

	}

	sendCreditUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('creditupdate', { data , roomName }, cb);}
	}

	sendAutoPutUpdate= ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('putautoupdate', { data , roomName }, cb);}
	}

	rgaAutoPutUpdate= ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('rgaautoupdate', { data , roomName }, cb);}
	}
	sendPutUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('putupdate', { data , roomName }, cb);}
	}

	sendPickUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('pickupdate', { data , roomName }, cb);}
	}

	sendMessage = ({ message, roomName }, cb) => {
		if (this.socket) {this.socket.emit('message', { message , roomName }, cb);}
	}

	sendCardUpdate = ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('updatecard', { data, roomName }, cb);}
	}

	sendDispatchUpdate = ({ data, roomName }, cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('dispatchupdate', { data, roomName }, cb);}
	}

	sendDispatchPositionUpdate = ({ data, roomName }, cb) => {

		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('dispatchpositionupdate', { data, roomName }, cb);}
	}

	sendLoadSheet = ({ data, roomName }, cb) => {
		if(!this.socket){
			this.setupSocketConnection(this.user.user.userid)
		}

		if (this.socket) {this.socket.emit('loadsheetupdate', { data, roomName }, cb);}
	}


	loadingBayAssigned = ({ data, roomName }, cb) => {
		if(!this.socket){
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('bayassigned', { data, roomName }, cb);}
	}

	subscribeToLoadingBays = (cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if(!this.socket.connected) {
			this.joinRoom('loadingbays');
		}
		this.socket.on('bayassigned', msg => {
			return cb(null, msg);
		});
	}

	subscribeToBayScans = (cb) =>{
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if(!this.socket.connected) {
			this.joinRoom('scanners');
		}
		this.socket.on('loadbayScan', msg => {
			return cb(null, msg);
		});
	}

	sendTruckClearedAlert = ({ data, roomName }, cb) =>{
		if(!this.socket) {
			this.setupSocketConnection(this.user.user.userid)
		}
		if (this.socket) {this.socket.emit('truckcleared', { data, roomName }, cb);}
	}

	subscribeToTruckClearAlerts = (cb) =>{
		if(!this.socket){
			this.setupSocketConnection(this.user.user.userid)
		}

		this.joinRoom('truckalerts');

		this.socket.on('truckcleared', msg=>{
			return cb(msg);
		})
	}

	joinRoom = (roomName) => {
		this.socket.emit('join', roomName);
	}

	public getConnection() {
		this.globalSearchService.socket.subscribe((results: any[]) => {
			this.socket = results;
		});
	}

	//HTTP REQUESTS
	public getPicks() {
		const method = 'oms/pick';
		return this.http.get(this.setEndPoint(method));
	}

	public getOrderCards() {
		const method = 'oms/cards';
		return this.http.get(this.setEndPoint(method));
	}

	public getRunPick(column: any) {
		const method = 'oms/runpick&column='+column;
		return this.http.get(this.setEndPoint(method));
	}

	public getRecalc(column) {
		const method = 'oms/recalc&column='+column+this.getKey();
		return this.http.get(this.setEndPoint(method));
	}

	private getApi() {
		return this.config.apiServer.crossPoint;
	}

	private getPackage() {
		const p = sessionStorage.getItem('app')
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	private getApiKey() {
		return '&key='+this.config.apiCredentials.clientId+'&userid='+this.user.user.userid;
	}

	public getKey() {
		return '&apikey='+this.key+'&userid='+this.user.user.userid;
	}

	private setEndPoint(method: any) {
		return this.setEndPoint(method)+this.getApiKey()+ this.getPackage()
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket.connect();
		}
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

}
