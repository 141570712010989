import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { Location } from '@angular/common'

@Component({
	selector: 'app-customer-settings',
	templateUrl: './customer-settings.component.html',
	styleUrls: ['./customer-settings.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerSettingsComponent implements OnInit {

	@Input() customer;
	@Output() editing: any = new EventEmitter < boolean > ();
	@Output() newcustomerdata = new EventEmitter < boolean > ();
	@ViewChild('matrix') matrixRef: ElementRef;

	settingsFrom: UntypedFormGroup;
	dcodeForm: UntypedFormGroup;
	salesman: any = [];
	salestypes: any = [];
	price_levels: any = [];
	dcodes: any = [];
	branches: any = [];
	mfgs: any = [];
	clubs: any = [];
	customertypes: any = [];
	customermatrix: any = false;
	searchText: any = '';
	filteredProductLines: any = [];
	constructor(private globalsService: GlobalsService, private fb: UntypedFormBuilder, private customerService: CustomerService, private inventoryService: InventoryService, private modalService: NgbModal, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {

		this.inventoryService.getPriceLevels().subscribe((r: any) => {
			this.price_levels = r.pricelevel;
		})

		//let customerdiscount = parseFloat(this.customer.customer.discount.replace(/\W/g, ''));
		const customerdiscount = parseFloat((parseFloat(this.customer.customer.discount) * 100).toFixed(4));

		this.settingsFrom = this.fb.group({
			debtorno: [this.customer.debtorno, [Validators.required]],
			branchcode: [this.customer.branchcode, [Validators.required]],
			username: [this.customer.username, [Validators.required]],
			password: [this.customer.password, [Validators.required]],
			salesman: [this.customer.customer.salesman, [Validators.required]],
			salestype: [this.customer.customer.salestype, [Validators.required]],
			salestype2: [this.customer.customer.salestype2],
			pricelevelprice: [this.customer.customer.price_type],
			typeid: [this.customer.customer.typeid, [Validators.required]],
			discount: [customerdiscount, [Validators.required]],
			disabletrans: [this.customer.customer.disabletrans],
			commpct: [this.customer.customer.commpct],
			clubid: [],
			clubacctid: [],
		});

		this.settingsFrom.get('salestype').valueChanges.subscribe(newValue => {
			if (newValue == 'M') {
				this.loadMatrix()
			}
		});
	}

	loadMatrix() {
		this.customerService.getPriceMatrix(this.customer.debtorno).subscribe(r => {
			this.customermatrix = r;
			this.filteredProductLines = [...this.customermatrix.productlines];
			this.modalService.open(this.matrixRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {}, (reason) => {
				this.customermatrix = false;
			});

		})
	}

	getCustomerMatrixDiscount(line: any) {

		if(this.customermatrix) {
			const r = this.customermatrix.customer_matrix.filter(v => {
				return v.lineid == line
			})[0];

			if (r) {
				return (r.discount) ? r.discount : '';
			}
		}
		return 0;
	}

	getCustomerMatrixLevel(line: any) {
		if(this.customermatrix) {
			const r = this.customermatrix.customer_matrix.filter(v => {
				return v.lineid == line
			})[0];

			if (r) {
				return (r.pricing_level) ? r.pricing_level : '';
			}
		}
		return '';
	}

	updateCustomerMatrix(line: any, value: any) {

		const r = this.customermatrix.customer_matrix.filter(v => {
			return v.lineid == line.line_field
		})[0];

		if (r) {
			const index = this.customermatrix.customer_matrix.indexOf(r);
			this.customermatrix.customer_matrix[index].discount = value;
		} else {

			const data = {
				'lineid': line.line_field,
				'pricing_level': value.pricing_level,
				'discount': value
			}

			this.customermatrix.customer_matrix.push(data);
		}
	}

	saveMatrix() {
		const data = {
			debtorno: this.customer.debtorno,
			matrix: this.customermatrix.customer_matrix
		}
		this.customerService.saveMatrix(data).subscribe(r => {
			this.globalSearchService.showNotification('Saved', 'success', 'bottom', 'right');
		})
	}
	addClub() {
		const data = {
			debtorno: this.customer.debtorno,
			clubid: this.settingsFrom.get('clubid').value,
			accountid: this.settingsFrom.get('clubacctid').value
		}
		this.customerService.addCustomerClub(data).subscribe(r => {
			if (r.success == true) {
				this.globalSearchService.showNotification('Saved', 'success', 'bottom', 'right');
				this.customer.clubs_joined.push({ club_id: r.id, club: r.club, accountid: r.accountid });
			} else {
				this.globalSearchService.showNotification(r.message, 'danger', 'bottom', 'right');
			}
		})
	}

	removeClub(club: any) {
		const data = {
			debtorno: this.customer.debtorno,
			clubid: club.club_id
		}
		this.customerService.removeCustomerClub(data).subscribe(r => {

			const index = this.customer.clubs_joined.indexOf(club);
			this.customer.clubs_joined.splice(index, 1)
			this.globalSearchService.showNotification('Removed', 'success', 'bottom', 'right');
		})
	}
	updateCustomerMatrixLevel(line: any, value: any) {

		const r = this.customermatrix.customer_matrix.filter(v => {
			return v.lineid == line.line_field
		})[0];

		if (r) {
			const index = this.customermatrix.customer_matrix.indexOf(r);
			this.customermatrix.customer_matrix[index].pricing_level = value;
		} else {

			const data = {
				'lineid': line.line_field,
				'pricing_level': value,
				'discount': line.discount
			}

			this.customermatrix.customer_matrix.push(data);
		}
	}

	ngAfterViewInit() {

		this.globalsService.getSalesman().subscribe(details => {
			this.salesman = details;
		});

		this.globalsService.getSalesTypes().subscribe(details => {
			this.salestypes = details;
		});

		this.globalsService.getCustomerTypes().subscribe(details => {
			this.customertypes = details;
		});

		this.customerService.getBranches(this.customer.debtorno).subscribe(details => {
			this.branches = details;
		});

		this.inventoryService.getMfgs().subscribe(details => {
			this.mfgs = details;
		});
		this.customerService.getClubs().subscribe(details => {
			this.clubs = details;
		});
	}

	salemanName(input: any) {
		const name = this.customer.salespeople.filter((p) => {
			return p.code == input
		})[0]
		return name.name
	}


	updateCustomerSettings() {
		this.customerService.updateCustomerSettings(this.settingsFrom.value).subscribe((results) => {
			this.editing.emit(false);
			this.newcustomerdata.emit(results);
		});

	}
	filterProductLines() {
		const search = this.searchText.toLowerCase();
		if (search) {
			this.filteredProductLines = this.customermatrix.productlines.filter(p =>
				p.line_field.toLowerCase().includes(search)
			);
		} else {
			this.filteredProductLines = [...this.customermatrix.productlines];
		}
	}

	clearSearch() {
		this.searchText = '';
		this.filterProductLines();
	}
}