<ng-container *ngIf="userForm">
	<div class="main-content">
		<div class="container-fluid">
			<div class="row m-0 p-0">
				<div class="col-md-12 m-0 p-0">
					<div class="card">
						<div class="card-header card-header-{{color}}-1">
							<h4 class="card-title"> <i class="material-icons" (click)="back()">arrow_left</i>Edit Profile</h4>
							<p class="card-category text-right">Update your profile</p>
						</div>
						<div class="card-body">
							<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
								<mat-tab label="Profile">
									<form [formGroup]="userForm" class="text-dark mt-3 profile-form" (submit)="updateUser()">
										<div class="card card-body">
											<div class="row">
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>User Id</mat-label>
														<input matInput formControlName="userid">
													</mat-form-field>
												</div>
												<div class="col-md-6" [ngClass]="isPosUser ? 'd-none' : ''">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Password</mat-label>
														<input matInput formControlName="password" type="password">
													</mat-form-field>
												</div>
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Name</mat-label>
														<input matInput formControlName="realname">
													</mat-form-field>
												</div>
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Email address</mat-label>
														<input matInput formControlName="email" type="email">
													</mat-form-field>
												</div>
											</div>

											<div class="row mt-3">
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Phone#</mat-label>
														<input matInput formControlName="phone" type="tel">
													</mat-form-field>
												</div>
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Default Location</mat-label>
														<mat-select formControlName="defaultlocation">
															<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
																{{ loc.loccode }} - {{ loc.locationname }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
											</div>

											<!-- Full Access Section -->
											<div class="row mt-3" *ngIf="user.fullaccess == '8'">
												<div class="col-md-6">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Full Access</mat-label>
														<input matInput formControlName="fullaccess" type="text">
													</mat-form-field>
												</div>
											</div>

											<!-- Additional Settings -->
											<div class="row mt-3" *ngIf="user.fullaccess == '8'">
												<div class="col-md-3">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Customer Admin</mat-label>
														<mat-select formControlName="customeradmin">
															<mat-option *ngFor="let yn of yesno" [value]="yn.value">{{ yn.name }}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Create Tender</mat-label>
														<mat-select formControlName="cancreatetender">
															<mat-option *ngFor="let yn of yesno" [value]="yn.value">{{ yn.name }}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Edit Inventory</mat-label>
														<mat-select formControlName="editinv">
															<mat-option *ngFor="let yn of yesno" [value]="yn.value">{{ yn.name }}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Dispatch Admin</mat-label>
														<mat-select formControlName="dispatchadmin">
															<mat-option *ngFor="let yn of yesno" [value]="yn.value">{{ yn.name }}</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
											</div>

											<!-- Color Picker -->
											<div class="row mt-4 mb-4 text-center">
												<div class="col-12">
													<label class="color-label">Color Schema</label>
													<div class="profile-plugin switch-trigger active-color">
														<span class="badge filter badge-purple" data-color="purple" (click)="changColor('purple')"></span>
														<span class="badge filter badge-azure" data-color="azure" (click)="changColor('azure')"></span>
														<span class="badge filter badge-green" data-color="green" (click)="changColor('green')"></span>
														<span class="badge filter badge-orange" data-color="orange" (click)="changColor('orange')"></span>
														<span class="badge filter badge-danger active" data-color="danger" (click)="changColor('danger')"></span>
													</div>
												</div>
											</div>

											<!-- Submit Button -->
											<div class="row">
												<div class="col-12 text-right">
													<button mat-raised-button type="submit" color="primary" class="update-button">Update Profile</button>
												</div>
											</div>
										</div>

									</form>
								</mat-tab>



								<mat-tab label="Printers">
									<div class="row m-0 p-0 mt-4">

										<!-- Available Devices Section -->
										<div class="col-md-6">
											<div class="section-header d-flex justify-content-between align-items-center">
												<h5 class="mat-title">Available Devices</h5>
												<a href="https://api.beautyserv.com/PrintConnectSetup1.4.6.zip" class="download-link">Download Print Connect</a>
											</div>
											<mat-divider></mat-divider>

											<!-- Search Box for Available Devices -->
											<mat-form-field appearance="outline" class="search-field w-100 mt-3">
												<mat-label>Search Available Devices</mat-label>
												<input matInput [(ngModel)]="searchAvailableDevices" placeholder="Search devices">
												<button mat-icon-button matSuffix (click)="searchAvailableDevices = ''" matTooltip="Clear search">
													<mat-icon>clear</mat-icon>
												</button>
											</mat-form-field>

											<!-- Available Devices as Cards -->
											<div *ngIf="filteredAvailableDevices().length === 0" class="text-center mt-4">
												<p class="text-muted">No printers available.</p>
											</div>

										<div class="row mt-3">
										  <div class="col-sm-12" *ngFor="let device of filteredAvailableDevices()">
											<mat-card class="device-card mb-3 p-3 shadow-sm">
											  <div class="d-flex justify-content-between align-items-center card-content">

												<!-- Device Name and IP on the Left -->
												<div class="device-info">
												  <mat-card-title class="font-weight-bold">{{ device.machine_name }}</mat-card-title>
												  <mat-card-subtitle class="text-muted">{{ device.ip }}</mat-card-subtitle>
												  <p class="mb-1">
													<small class="text-secondary">User: {{ device.machine_user }}</small>
												  </p>
												</div>

												<!-- Device Types Selector and Add Button on the Right -->
												<div class="device-actions d-flex align-items-center">
												  <mat-form-field appearance="outline" class="device-type-selector mr-2 full-width">
													<mat-label>Device Types</mat-label>
													<mat-select multiple [(ngModel)]="selectedDeviceTypes[device.device_id]" placeholder="Select device types">
													  <mat-option *ngFor="let type of deviceTypes" [value]="type">{{ type }}</mat-option>
													</mat-select>
												  </mat-form-field>
												  <button mat-raised-button color="primary" class="ml-2" (click)="addUserDevice(device)" matTooltip="Add Device">
													<mat-icon>add</mat-icon>
												  </button>
												</div>
											  </div>
											</mat-card>
										  </div>
										</div>

										</div>

										<!-- User Devices Section -->
										<div class="col-md-6">
											<h5 class="mat-title">User Devices</h5>
											<mat-divider></mat-divider>

											<!-- Search Box for User Devices -->
											<mat-form-field appearance="outline" class="search-field w-100 mt-3">
												<mat-label>Search User Devices</mat-label>
												<input matInput [(ngModel)]="searchUserDevices" placeholder="Search user devices">
												<button mat-icon-button matSuffix (click)="searchUserDevices = ''" matTooltip="Clear search">
													<mat-icon>clear</mat-icon>
												</button>
											</mat-form-field>

											<!-- User Devices as Cards -->
											<div *ngIf="filteredUserDevices().length === 0" class="text-center mt-4">
												<p class="text-muted">No user printers set.</p>
											</div>

											<div class="row mt-3">
												<div class="col-sm-12" *ngFor="let device of filteredUserDevices()">
													<mat-card class="device-card mb-3 p-3 shadow-sm">
														<div class="d-flex justify-content-between align-items-center card-content">

															<!-- Device Name, IP, and Device ID on the Left -->
															<div class="device-info">
																<mat-card-title class="font-weight-bold">{{ device.machineName }}</mat-card-title>
																<mat-card-subtitle class="text-muted">{{ device.machineUser }}</mat-card-subtitle>
															</div>

															<!-- Print Types Selector and Remove Button on the Right -->
															<div class="device-actions d-flex align-items-center">
																<mat-form-field appearance="outline" class="device-type-selector mr-2 full-width">
																	<mat-label>Print Types</mat-label>
																	<mat-select multiple [(ngModel)]="device.deviceTypes" (selectionChange)="updatePrintSettings(device.deviceID, device.machineName, device.deviceTypes)" placeholder="Select print types">
																		<mat-option *ngFor="let type of deviceTypes" [value]="type">{{ type }}</mat-option>
																	</mat-select>
																</mat-form-field>
																<button mat-raised-button color="warn" class="ml-2" (click)="removeUserDevice(device)" matTooltip="Remove Device">
																	<mat-icon>delete</mat-icon>
																</button>
															</div>
														</div>
													</mat-card>
												</div>
											</div>

										</div>
									</div>
								</mat-tab>

							</mat-tab-group>
						</div>
					</div>
				</div>
				<div class="col-md-4 d-none">
					<div class="card card-profile">
						<div class="card-avatar">
							<a href="javascript:void(0)">
								<img class="img" src="./assets/img/faces/marc.jpg" />
							</a>
						</div>
						<div class="card-body">
							<h6 class="card-category text-gray">CEO / Co-Founder</h6>
							<h4 class="card-title">{{ userdetails.realname }}</h4>
							<p class="card-description">
								Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
							</p>
							<a href="javascript:void(0)" class="btn btn-danger btn-round">Follow</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>