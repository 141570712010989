<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-1">
				<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Open Orders</h4>
				<p class="card-category">Open Sales Orders</p>
			</div>
			<div class="card-body">
				<app-open-orders [customerdata]="''" (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" (preorderloaded)="loadPreOrder($event);"></app-open-orders>
			</div>
		</div>
	</div>
</div>
