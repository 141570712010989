import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service'
import { interval, Subscription, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-customer-inactive',
	templateUrl: './customer-inactive.component.html',
	styleUrls: ['./customer-inactive.component.scss'],
	animations: [
		  trigger('flipState', [
			  state('active', style({
				  transform: 'rotate3d(0, 1, 0, -179deg)',
			  })),
			  state('inactive', style({
				  transform: 'rotateY(0)'
			  })),
			  state('orderlookup', style({
				  transform: 'rotateY(-179deg)'
			  })),
			  transition('active => inactive', animate('500ms ease-out')),
			  transition('inactive => active', animate('500ms ease-in')),
			  transition('inactive => orderlookup', animate('500ms ease-out')),
			  transition('orderlookup => inactive', animate('500ms ease-in')),
		  ]),
		  trigger('openClose', [
			  state(':enter', style({ height: '*' })),
			  state(':leave', style({ height: '0px' })),
			  transition('false <=> true', animate(500))
		  ]),
		  trigger('filterAnimation', [
			  transition(':enter, * => 0, * => -1', []),
			  transition(':increment', [
				  query(':enter', [
					  style({ opacity: 0, width: 0 }),
					  stagger(50, [
						  animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					  ]),
				  ], { optional: true })
			  ]),
			  transition(':decrement', [
				  query(':leave', [
					  stagger(50, [
						  animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					  ]),
				  ])
			  ]),
		  ]),
		  trigger('grow', [ // Note the trigger name
			  transition(':enter', [
				  // :enter is alias to 'void => *'
				  style({ height: '0', width: '0' }),
				  animate(200, style({ height: '*', width: '*' })),
				  animate('200ms', style({ opacity: 1 })),
			  ]),
			  transition(':leave', [
				  // :leave is alias to '* => void'
				  animate(100, style({ height: 0, width: 0 })),
				  animate('100ms', style({ opacity: 0 }))
			  ])
		  ]),
	  ]
})
export class CustomerInactiveComponent implements OnInit {
	@Input() flip = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;

	private sort: MatSort;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 200, 500];
	pagesizedefault = 200;

	viewing: any = false;
	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	defaultlocation = new UntypedFormControl('');
	data: any = false;
	sending: any = false;
	days: any = 60;
	daysmax: any = 365;
	title = 'Inactive Customers';
	total: any = 0;
	brand_details: any = false;
	constructor( private _changeDetectorRef: ChangeDetectorRef,private printService: PrintService,public globalSearchService: GlobalSearchService,private customerService: CustomerService, private modalService: NgbModal) {}
	user: any = false;
	ref_location : any = false;
	filename = 'Inactive Customers';
	ngOnInit(): void {
		this.loadData();
		this.setPagination([]);
		this.globalSearchService.user.subscribe( results => {
			//only run if user is definied
			if(this.user) {
				if(results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results
			if(!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});
	}

	loadData() {
		const data = { numberofdays: this.days, daysmax: this.daysmax }
		this.sending = true;
		this.customerService.getInactiveCustomersCount(data).subscribe((r: any) => {
			this.total = r.total;
			this.customerService.getInactiveCustomers(data).subscribe((results: any) => {
				if(results.success){
					this.sending = false;
					this.data = results.data;
					this.setPagination(this.data);
				}
			});
		});
	}

	getBrandSales(debtorno: any, brand: any) {

		const today = new Date();
		const priorDate = new Date(new Date().setDate(today.getDate() - this.daysmax));

		const data = {
			debtorno: debtorno,
			brands: [brand],
			from: 'all',
			//to: today,
		}

		this.customerService.searchCustomerHistory(data).subscribe(async (results: any) => {
			this.sending = false;
			this.brand_details = results;
			this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	getCostTotalYear(year: any) {
		const value = year.cost.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getSaleTotalYear(year: any) {
		const value = year.sales.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	getQtyTotalYear(year: any) {
		const value = year.qty.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	updateDays(event: any) {
		this.days = event.target.value
	}

	updateDaysMax(event: any) {
		this.daysmax = event.target.value
	}

	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			const dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}
}
