import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../../services/orders.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { UsersService } from '../../../services/users.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {
	transactionid: any = '';
	invoice: any = [];
	reprintlink: any = '';
	printed = false;
	config: any = [];
	color: any = 'blue';

	baselink = '';
	isPosUser = false;
	constructor(private usersService: UsersService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.transactionid = params.id
			this.orderService.getTransactionById(params.id).subscribe((details: any) => {
				this.usersService.isPosUser().subscribe((isPOS) => {
					if (isPOS) {
						this.isPosUser = true;
					}

					this.invoice = details
					this.reprintlink = this.config.apiServer.baseUrl + this.config.apiServer.invoiceLink + details.id + '&key=' + this.config.apiCredentials.clientId;
					if (!this.printed) {
						this.printed = true;
					}
				})
			})
		})
	}

	goToLink(url: string) {
		window.open(url);
	}

}