<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12 ">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title pointer"><i class="material-icons" (click)="back()">arrow_left</i> <span class="bold">Inventory Promos</span> </h4>
				</div>
				<div class="card-body no-overflow">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Promos">
							<ng-container *ngIf="!editingpromo && !newpromo">
								
								<div class="text-left ml-0 mr-auto mt-2 mb-2">
									<span *ngIf="sortedPromos"> COUNT {{ sortedPromos.length }}</span>
								</div>
								<div class="text-right ml-auto mr-0 mt-2 mb-2">
									<button mat-raised-button color="accent" (click)="newpromo = true;setForm();" class="ml-auto mr-0">CREATE PROMO</button>
								</div>
								<div class="table mt-2">
									<mat-form-field appearance="outline">
										<mat-label>Search</mat-label>
										<input matInput value="" placeholder="Search..." (input)="searchPromos($event.target.value)" [formControl]="searchpromo" />
									</mat-form-field>
									<table class="table table-fixed table-hover">
										<thead class="thead-dark sticky-top">
											<tr>
												<th (click)="sortTable('stockid')">
													<mat-icon>{{ getSortIcon('stockid') }}</mat-icon> Item
												</th>
												<th (click)="sortTable('name')">
													<mat-icon>{{ getSortIcon('name') }}</mat-icon> Name
												</th>
												<th (click)="sortTable('typedesc')">
													<mat-icon>{{ getSortIcon('typedesc') }}</mat-icon> Type
												</th>
												<th class="text-center" (click)="sortTable('price')">
													<mat-icon>{{ getSortIcon('price') }}</mat-icon> Price
												</th>
												<th class="text-center" (click)="sortTable('discount')">
													<mat-icon>{{ getSortIcon('discount') }}</mat-icon> Discount
												</th>
												<th class="text-center" (click)="sortTable('stock')">
													<mat-icon>{{ getSortIcon('stock') }}</mat-icon> QOH
												</th>
												<th class="text-center" (click)="sortTable('start_date')">
													<mat-icon>{{ getSortIcon('start_date') }}</mat-icon> Start
												</th>
												<th class="text-center" (click)="sortTable('end_date')">
													<mat-icon>{{ getSortIcon('end_date') }}</mat-icon> Stop
												</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngFor="let p of sortedPromos">
												<tr>
													<td>{{ p.stockid }}</td>
													<td [innerHTML]="noNewLines(p.name)"></td>
													<td>{{ p.typedesc }}</td>
													<td class="text-center">{{ p.price | currency }}</td>
													<td class="text-center">{{ p.discount | percent }}</td>
													<td class="text-center">{{ p.stock }}</td>
													<td class="text-center">{{ p.start_date }}</td>
													<td class="text-center">{{ p.end_date }}</td>
													<td>
														<button mat-icon-button color="danger" (click)="removePromo(p);">
															<mat-icon>remove</mat-icon>
														</button>
														<button mat-icon-button color="accent" (click)="loadPromo(p);">
															<mat-icon>edit</mat-icon>
														</button>
													</td>
												</tr>
											</ng-container>
										</tbody>
									</table>


								</div>
							</ng-container>

							<ng-container *ngIf="editingpromo || newpromo ">
								<div class="text-right ml-auto mr-0 mt-2 mb-2">
									<button mat-raised-button color="primary" (click)="editingpromo = false;newpromo = false;">CLOSE</button>
								</div>
								<div class="card">
									<div class="card-header">
										<h4>Promo Setup</h4>
									</div>
									<div class="card-body">
										<form [formGroup]="itemForm" class="needs-validation product-add" novalidate="" autocomplete="off">
											<div class="row">
												<div class="col-md-3">
													<mat-form-field appearance="outline" class="">
														<mat-label>Brand</mat-label>
														<mat-select required formControlName="brand">
															<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">
																{{ k.name }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline" class="">
														<mat-label>Category</mat-label>
														<mat-select required formControlName="categoryid">
															<mat-option *ngFor="let k of properties.categories" [value]="k.value">
																{{ k.name }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline" class="">
														<mat-label>Product Line</mat-label>
														<mat-select required formControlName="lineid">
															<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
																{{ k.name }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Vendor</mat-label>
														<input #vendorsearch matInput value="" placeholder="Vendor Search..." (input)="vendorSearch($event.target.value)" (keydown.enter)="$event.preventDefault();" formControlName="supplierno" [matAutocomplete]="vendorLookup">
														<span matSuffix class="ripple-container"><i class="material-icons">search</i></span>
														<mat-autocomplete #vendorLookup="matAutocomplete" [displayWith]="displayFn">
															<mat-option *ngFor="let v of vendorresults" [value]="v">
																{{ v.supplierid }} - {{ v.suppname }}
															</mat-option>
														</mat-autocomplete>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>MfgPart#</mat-label>
														<input #mfgpart autofocus autocomplete="off" aria-hidden="false" matInput formControlName="mfgpart" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Item#</mat-label>
														<input #stockidsearchtwo autofocus autocomplete="off" aria-hidden="false" matInput formControlName="stockid" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>

												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Name</mat-label>
														<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="name" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Full Description</mat-label>
														<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="description" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<mat-divider></mat-divider>

											</div>
											<mat-divider></mat-divider>
											<div class="row">
												<div class="col-md-3">
													<mat-form-field appearance="outline" class="">
														<mat-label>Promo type</mat-label>
														<mat-select required formControlName="promotype">
															<mat-option *ngFor="let k of promo_types" [value]="k.value">
																{{ k.label }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Price</mat-label>
														<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="price" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Discount</mat-label>
														<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="discount" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<div class="col-md-3">
													<mat-form-field appearance="outline">
														<mat-label>Cost</mat-label>
														<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="cost" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); ">
													</mat-form-field>
												</div>
												<div class="col-md-6">
													<mat-form-field appearance="outline">
														<mat-label>Start date</mat-label>
														<input matInput [matDatepicker]="startdate" formControlName="startdate" class="text-center">
														<mat-hint>MM/DD/YYYY</mat-hint>
														<mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
														<mat-datepicker #startdate></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="col-md-6">
													<mat-form-field appearance="outline">
														<mat-label>End date</mat-label>
														<input matInput [matDatepicker]="enddate" formControlName="enddate" class="text-center">
														<mat-hint>MM/DD/YYYY</mat-hint>
														<mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
														<mat-datepicker #enddate></mat-datepicker>
													</mat-form-field>
												</div>
											</div>
											<mat-spinner matPrefix diameter="30" *ngIf="saving"></mat-spinner>
											<button class="btn btn-danger addtopromo" value="1" (click)="addPromo();" *ngIf="!saving"><span *ngIf="editingpromo">UPDATE</span><span *ngIf="newpromo">SAVE</span> PROMO</button>

										</form>
										<div class="table table-sm  table-fluid container-fluid" *ngIf="!newpromo">
											<h4>Item In Promotion</h4>
											<table class="table table-striped table-fixed table-hover scrollsmall">
												<thead>
													<tr>
														<th>Item#</th>
														<th>Description</th>
														<th>Price</th>
														<th>Discount</th>
														<th>Qty</th>
														<th>Type</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngIf="promoitems">
														<tr *ngFor="let p of promoitems">
															<td>{{ p.item }}</td>
															<td>{{ p.description }}</td>
															<td>{{ p.price }}</td>
															<td>{{ p.discount | percent }}</td>
															<td>{{ p.qty }}</td>
															<td>{{ p.typedesc }}</td>
															<td><a class="remove text-danger" (click)="removePromoItem(p)">Remove</a></td>
														</tr>
													</ng-container>
													<ng-container *ngIf="!promoitems">
														<tr>
															<td colspan="8">
																<div class="alert alert-info"> No Items Added, please search and items below</div>
															</td>
														</tr>
													</ng-container>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<mat-divider *ngIf="!newpromo"></mat-divider>
								<div class="card" *ngIf="!newpromo">
									<h4 class="card-header">Add Items to Promo</h4>
									<div class="card-body">
										<mat-form-field appearance="outline" class="smaller-font-form notop text-right" color="accent">
											<mat-spinner matPrefix diameter="30" *ngIf="searching"></mat-spinner>
											<span matPrefix *ngIf="allitems.length && !searching">
												<button mat-icon-button class="p-0" (click)="clearForm()">
													<mat-icon class="p-0">clear</mat-icon>
												</button>
											</span>
											<input #itemSearch matInput [formControl]="itemSearchControl" placeholder="Search By PartNo/Description..." autofocus="true" class="w-100 m-0 p-0 notop text-right" (input)="searchItems()" (keyup.enter)="searchItems()">
											<span matSuffix>
												<button mat-icon-button color="white" (click)="searchItems()">
													<mat-icon class="p-0">search</mat-icon>
												</button>
											</span>
										</mat-form-field>

										<div class="table table-fluid table-sm mt-3" *ngIf="allitems">
											<table class="table table-fluid table-sm table-bordered table-striped">
												<tbody>
													<tr>
														<th>Item#</th>
														<th>Desc</th>
														<th>Type</th>
														<th class="text-right">Price</th>
														<th class="text-right">Discount</th>
														<th class="text-center">Max Qty</th>
														<th class="text-center">Qty</th>
														<th></th>
													</tr>
													<tr *ngFor="let item of allitems">
														<td [ngClass]="item.discontinued ? 'text-danger': ''">
															{{ item.stockid }} <small><b>{{ item.supplierno }}</b></small>
														</td>
														<td>
															{{ item.description }}
														</td>
														<td>
															<b>{{ item.typedesc }}</b>
															<input type="hidden" name="itemtype[{{ item.stockid}}]" value="{{item.type}}" />
														</td>
														<td>
															<input type="text" name="price[{{ item.stockid }}]" value="{{ item.inputprice }}" placeholder="{{ item.price }}" class="form-control text-right " (input)="item.inputprice = $event.target.value" />
														</td>
														<td>
															<input type="text" name="discount[{{ item.stockid }}]" value="{{ item.discount }}" placeholder="0" class="form-control text-right" (input)="item.discount = $event.target.value" />
														</td>
														<td class="text-center">
															<input type="text" name="max[{{ item.stockid }}]" value="0" placeholder="0" class="form-control  text-center" />
														</td>
														<td class="text-center">
															<input type="text" name="qty[{{ item.stockid }}]" value="1" placeholder="1" class="form-control text-center" (input)="item.quantity = $event.target.value" />
														</td>
														<td>
															<button class="btn btn-danger addtopromo" value="1" data-id="{{ item.stockid }}" (click)="addPrommoItem(item);">Add </button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>

									</div>
								</div>

							</ng-container>

						</mat-tab>
						<mat-tab label="Mix & Match">
							<div class="text-left ml-0 mr-auto mt-2 mb-2">
								<span *ngIf="sortedMMs"> COUNT {{ sortedMMs.length }}</span>
							</div>
							<div class="text-right ml-auto mr-0 mt-2 mb-2" *ngIf="!newmm && !editingmm">
								<button mat-raised-button color="accent" (click)="toggleNewMM()" class="ml-auto mr-0">CREATE MIX & MATCH</button>
							</div>
							
							
							
							<ng-container *ngIf="!editingmm && !newmm">
								<mat-form-field appearance="outline">
									<mat-label>Search</mat-label>
									<input matInput value="" placeholder="Search..." (input)="searchMix($event.target.value)" [formControl]="searchmix" />
								</mat-form-field>
								<table id="mm" class="table table-sm table-bordered table-hover">
									<thead class="thead-dark sticky-top">
										<tr>
											<th (click)="sortMMTable('stockid')">
												<mat-icon>{{ getMMSortIcon('stockid') }}</mat-icon> Item
											</th>
											<th (click)="sortMMTable('name')">
												<mat-icon>{{ getMMSortIcon('name') }}</mat-icon> Name
											</th>
											<th (click)="sortMMTable('start_date')">
												<mat-icon>{{ getMMSortIcon('start_date') }}</mat-icon> Start
											</th>
											<th (click)="sortMMTable('stop_date')">
												<mat-icon>{{ getMMSortIcon('stop_date') }}</mat-icon> Stop
											</th>
											<th>
												Qty
											</th>
											<th class="text-center" (click)="sortMMTable('products')">
												<mat-icon>{{ getMMSortIcon('products') }}</mat-icon> Products
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let p of sortedMMs">
											<tr>
												<td>{{ p.stockid }}</td>
												<td [innerHTML]="noNewLines(p.name)"></td>
												<td>{{ p.start_date }}</td>
												<td>{{ p.stop_date }}</td>
												<td class="text-center">{{ p.floor }} </td>
												<td class="text-center">{{ p.products }}</td>
												<td>
													<button mat-icon-button color="danger" (click)="removeMM(p.mmid);">
														<mat-icon>remove</mat-icon>
													</button>
													<button mat-icon-button color="accent" (click)="loadMM(p);">
														<mat-icon>edit</mat-icon>
													</button>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>

							</ng-container>
							<ng-container *ngIf="editingmm || newmm">
								<div class="text-right ml-auto mr-0 mt-2 mb-2">
									<button mat-raised-button color="primary" (click)="closeMM()">CLOSE</button>
								</div>
								<form [formGroup]="itemMMForm" class="needs-validation product-add" novalidate="" autocomplete="off" (ngSubmit)="$event.preventDefault();">
									<div class="row  m-0 p-0">
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Item#</mat-label>
												<input #stockidsearchtwo autofocus autocomplete="off" aria-hidden="false" matInput formControlName="stockid" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); " appNoEnterKey>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Name</mat-label>
												<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="name" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); " appNoEnterKey>
											</mat-form-field>
										</div>
									</div>
									<div class="row  m-0 p-0">
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Mix And Match Total #</mat-label>
												<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="floor" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); " appNoEnterKey appNumericField>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Subtotal $</mat-label>
												<input autofocus autocomplete="off" aria-hidden="false" matInput formControlName="subtotal" required value="" class="text-right" (keyup.enter)="$event.preventDefault(); " appNoEnterKey appNumericField>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>Start date</mat-label>
												<input matInput [matDatepicker]="startdate" formControlName="startdate" class="text-center" appNoEnterKey>
												<mat-hint>MM/DD/YYYY</mat-hint>
												<mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
												<mat-datepicker #startdate></mat-datepicker>
											</mat-form-field>
										</div>
										<div class="col-md-6">
											<mat-form-field appearance="outline">
												<mat-label>End date</mat-label>
												<input matInput [matDatepicker]="enddate" formControlName="enddate" class="text-center" appNoEnterKey>
												<mat-hint>MM/DD/YYYY</mat-hint>
												<mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
												<mat-datepicker #enddate></mat-datepicker>
											</mat-form-field>
										</div>
									</div>
									<ng-container *ngIf="newmm">
										<button class="btn btn-danger addtopromo" value="1" (click)="createMM();" *ngIf="!saving">
											<span *ngIf="editingmm">UPDATE</span><span *ngIf="newmm">SAVE</span> MIX & MATCH AND ADD ITEMS</button>
									</ng-container>
									<div class="col-12" *ngIf="exists || editingmm">
										<div class="card-headers">
											<h3>Mix and Match Items by: (rule)</h3>
										</div>
										<mat-spinner *ngIf="saving"></mat-spinner>
										<app-inventory-searcher (saveresults)="saveMm($event)" [rule]="mm" [forceupdate]="triggersearch"></app-inventory-searcher>
									</div>
								</form>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>
