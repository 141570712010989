import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { CustomerService } from 'app/services/customer.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { interval, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pos-sales-report',
  templateUrl: './pos-sales-report.component.html',
  styleUrls: ['./pos-sales-report.component.scss']
})
export class PosSalesReportComponent {
	@ViewChild('print_table') printtable: ElementRef;
	color: any = 'blue';
	config: any = [];
	public searchForm: UntypedFormGroup;
	cities: any = [];
	branches: any = [];
	paymentterms: any = [];
	clubs: any = [];
	sending: any = false;
	orderResults: any;
	ppdvend: any = [];
	partvend: any = [];
	indvend: any = [];
	miscvend: any = [];
	fromdate: any = '';
	todate: any = '';
	filename: any = 'SalonCentric_POS_';
	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;

	ngOnInit(): void {}
	constructor(private _changeDetectorRef: ChangeDetectorRef, private customerService: CustomerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, private printService: PrintService, private globalsService: GlobalsService, private reportsService: ReportsService) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.reportsService.getSalonCentricVendors().subscribe((results: any) => {
			this.ppdvend = results.ppd;
			this.partvend = results.part;
			this.indvend = results.ind;
			this.miscvend = results.misc;
		});
		
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;
		this.todate = today;
		
		this.searchForm = this.fb.group({
			startdate: [this.fromdate],
			enddate: [this.todate],
			ppvend: [''],
			ivend: [''],
			pvend: [''],
			mvend: [''],
			branch: [''],		
		});
		//this.setPagination([]);
	}
		
	back(){
	
	}
	
	getCustExport(){
	
		this.sending = true;
		let vendorlist = [];
		if(this.searchForm.get('ppvend').value != ''){
		vendorlist = vendorlist.concat(this.searchForm.get('ppvend').value);
		}
		if(this.searchForm.get('ivend').value != ''){
		vendorlist = vendorlist.concat(this.searchForm.get('ivend').value);
		}
		if(this.searchForm.get('pvend').value != ''){
		vendorlist = vendorlist.concat(this.searchForm.get('pvend').value);
		}
		if(this.searchForm.get('mvend').value != ''){
		vendorlist = vendorlist.concat(this.searchForm.get('mvend').value);
		}
		
		const data = { startdate: this.searchForm.get('startdate').value, enddate: this.searchForm.get('enddate').value, branches: this.searchForm.get('branch').value,
						vendors: vendorlist };
		this.reportsService.getSalonCentricReport(data).subscribe(r => {
			this.sending = false;
			this.orderResults = r;
			this.setPagination(this.orderResults);
		});
	}
	
	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const start = new Date(this.searchForm.get('startdate').value);
		const end = new Date(this.searchForm.get('enddate').value);
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' SalonCentric POS Sales '+ (start.getMonth()+1) +'/'+start.getDate()+'/'+start.getFullYear()+'-'+(end.getMonth()+1) +'/'+end.getDate()+'/'+end.getFullYear(),
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const today = new Date();
		const start = new Date(this.searchForm.get('startdate').value);
		const end = new Date(this.searchForm.get('enddate').value);
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename+ today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' SalonCentric POS Sales '+ (start.getMonth()+1) +'/'+start.getDate()+'/'+start.getFullYear()+'-'+(end.getMonth()+1) +'/'+end.getDate()+'/'+end.getFullYear(),
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
}
